
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class FlowList extends Vue {
  // 业务类型
  private businessType = ['入库', '出库']
  // 仓库
  private wareHouseList = []
  // 物料分类
  private materialTypeData = []

  private info = {
    // 业务类型
    detailedType: '',
    // 项目
    projectId: '',
    // 仓库
    warehouseId: '',
    // 物料分类
    materialTypeId: '',
    // 物料名称
    material: '',
    // 单号
    detailedNum: ''
  }

  private dateRange = []

  private tableData = {
    loading: false,
    data: []
  }

  private page = 1
  private size = 10
  private total = 0

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getData()
    this.loadMaterialType() // 物料类型
  }

  loadMaterialType () {
    this.$axios.get(this.$apis.material.selectYhMaterialTypeByPage).then(res => {
      this.materialTypeData = res.list
    })
  }

  getData () {
    console.log('this.$apis.material.selectYhDetailedByPage', this.$apis.material.selectYhDetailedByPage)
    this.tableData.loading = true
    this.$axios.get(this.$apis.material.selectYhDetailedByPage, {
      ...this.info,
      startTime: this.dateRange ? this.dateRange[0] || '' : '',
      endTime: this.dateRange ? this.dateRange[1] || '' : '',
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  // 仓库
  getWareHouseList () {
    this.info.warehouseId = ''
    this.$axios.get(this.$apis.material.selectYhWarehouseByList, { projectId: this.info.projectId }).then(res => {
      this.wareHouseList = res.list || []
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
